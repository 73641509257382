import React from 'react';

import SEO from '../components/seo';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import { useStaticQuery, graphql } from 'gatsby';

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "404.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Hero
        overflow="hidden"
        imageHeight="100%"
        showArrow={false}
        button={{
          text: 'Go to the Homepage',
          to: '/',
          variant: 'white',
          mt: 3,
        }}
        title="Something's disconnected"
        description="Sorry, we couldn’t find the page you were looking for.
This is either a broken link or it was moved somewhere else."
        imageFluid={data.file.childImageSharp.fluid}
      />
    </Layout>
  );
};

export default NotFoundPage;
